import {Type} from 'class-transformer';
import {ClassedEntity} from '../../../../../domain/ClassedEntity';
import {CompanyDenomination} from '../../company/CompanyDenomination';
import {Reference} from '../../../../../domain/Reference';
import {SelectableRef} from '../../../../../domain/SelectableRef';
import {UserRegistrationProgress} from './UserRegistrationProgress';
import {UserRolesEnum} from './UserRolesEnum';
import {BlacklistPepMode} from '../../../../blacklist-pep/domain/BlacklistPepMode';

export class User extends ClassedEntity {
  constructor() {
    super('com.beclm.user.api.user.User');
  }

  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'user';

  login: string;
  roles: string[] = [];
  activeBlacklistPepLevel: string;
  email: string;
  lastName: string;
  firstName: string;
  phoneNumber: string;
  mobilePhoneNumber: string;
  @Type(() => CompanyDenomination)
  companies: CompanyDenomination[];
  disabled: boolean;
  @Type(() => UserRegistrationProgress)
  userRegistrationProgress: UserRegistrationProgress;

  public static toSelectableRef(
    reference: Reference<User>
  ): SelectableRef<User> {
    const selectableRef = new SelectableRef<User>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label = reference.entity.login;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<User> {
    const selectableRef = new SelectableRef<User>();
    selectableRef.id = new Reference<User>(this);
    selectableRef.__label = this.login;
    return selectableRef;
  }

  isAdminOrRestxAdmin(): boolean {
    return this.isAdmin() || this.isRestxAdmin();
  }

  isAdmin(): boolean {
    return this.roles.indexOf(UserRolesEnum.ADMIN) !== -1;
  }

  isRestxAdmin(): boolean {
    return this.roles.indexOf(UserRolesEnum.RESTX_ADMIN) !== -1;
  }

  isCompanyAdmin(): boolean {
    return this.roles.indexOf(UserRolesEnum.COMPANY_ADMIN) !== -1;
  }

  isCompanyAdminOrHigher(): boolean {
    return this.isAdmin() || this.isRestxAdmin() || this.isCompanyAdmin();
  }

  isInspector(): boolean {
    return this.roles.indexOf(UserRolesEnum.INSPECTOR) !== -1;
  }

  isBlacklist(): boolean {
    return this.roles.indexOf(UserRolesEnum.BLACKLIST) !== -1 || this.isAnalystL2();
  }

  isMultipleBlacklist(): boolean {
    return this.roles.indexOf(UserRolesEnum.BLACKLIST) !== -1 && this.isAnalystL2();
  }

  isPEP(): boolean {
    return this.roles.indexOf(UserRolesEnum.PEP) !== -1 || this.isAnalystL2();
  }

  isMultiplePEP(): boolean {
    return this.roles.indexOf(UserRolesEnum.PEP) !== -1 && this.isAnalystL2();
  }

  isAdverseMedia(): boolean {
    return this.roles.indexOf(UserRolesEnum.ADVERSE_MEDIA) !== -1 || this.isAnalystL2()
  }

  isMultipleAdverseMedia(): boolean {
    return this.roles.indexOf(UserRolesEnum.ADVERSE_MEDIA) !== -1 && this.isAnalystL2();
  }

  isSearch(): boolean {
    return  this.roles.indexOf(UserRolesEnum.SEARCH) !== -1;
  }

  isAnalystL2(): boolean {
    return this.roles.indexOf(UserRolesEnum.ANALYST_L2) !== -1;
  }

  isImporter(): boolean {
    return this.roles.indexOf(UserRolesEnum.IMPORTER) !== -1;
  }

  isBlacklistPepAmOnMode(mode: BlacklistPepMode): boolean {
    return (
      (mode == BlacklistPepMode.BLACKLIST && this.isBlacklist()) ||
      (mode == BlacklistPepMode.PEP && this.isPEP()) ||
      (mode == BlacklistPepMode.ADVERSE_MEDIA && this.isAdverseMedia())
    );
  }
}

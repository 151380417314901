import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, filter, finalize, map, switchMap} from 'rxjs/operators';
import {Company} from '../modules/core/domain/company/Company';
import {User} from '../modules/core/domain/user/user/User';
import {UserService} from '../modules/core/services/user/user.service';
import {ModuleService} from './module.service';
import {CompanyService} from '../modules/core/services/company/company.service';
import {AuthenticationService} from './authentication.service';
import {plainToClass} from 'class-transformer';
import {Md5} from 'ts-md5';
import {Session} from '../domain/Session';

@Injectable()
export class SessionService {
  private readonly baseUrl = '/api/sessions';

  private _selectedCompany = new BehaviorSubject<Company>(undefined);
  private _currentUser = new BehaviorSubject<User>(undefined);

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private moduleService: ModuleService,
    private companyService: CompanyService,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.isAuthenticated().subscribe((value) => {
      if (value == true) {
        this.reloadSession();
      }
    });
  }

  authenticate(loginToken: string): Observable<void> {
    return this.authenticationService.authenticate(loginToken);
  }

  authenticateFromLoginAndPassword(
    login: string,
    password: string,
    googleCaptchaToken: string
  ): Observable<Session> {
    const session: Session = new Session();
    session.principal.name = login;
    session.principal.passwordHash = <string>Md5.hashStr(password);

    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.httpClient.post<Session>(`${url}?generateLoginToken=true&googleCaptchaToken=${googleCaptchaToken}`, session)
      ),
      map((result: any) => plainToClass(Session, result as Object))
    );
  }

  currentSession(generateLoginToken = false): Observable<Session> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.httpClient.get<Session>(
          url + '/current?generateLoginToken=' + generateLoginToken
        )
      ),
      map((result: any) => plainToClass(Session, result as Object))
    );
  }

  logout(): Observable<void> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
          switchMap((url) => this.httpClient.delete<void>(url)),
          finalize(() => {
            localStorage.removeItem(this.authenticationService.SESSION_AUTH_KEY);
            this._selectedCompany = new BehaviorSubject<Company>(undefined);
            this._currentUser = new BehaviorSubject<User>(undefined);
          }),
        );
  }

  selectedCompany(): Observable<Company> {
    return this._selectedCompany.pipe(filter((v) => v !== undefined));
  }

  currentUser(): Observable<User> {
    return this._currentUser.pipe(filter((v) => v !== undefined));
  }

  isL2(): Observable<boolean> {
    return this.currentUser().pipe(switchMap((user) => of(user.roles.indexOf('ANALYST_L2') !== -1)));
  }

  reloadSession(success?: (user: User) => void): void {
    this.reloadCurrentUser((user) => {
      if (user.companies.length > 0) {
        this.reloadSelectedCompany(() => {
          if (success != null) {
            success(user);
          }
        });
      } else if (success != null) {
        success(user);
      }
    });
  }

  reloadCurrentUser(success?: (user: User) => void): void {
    this.userService
      .current()
      .pipe(
        catchError((error) => {
          if (error.status === 403) {
            this.moduleService
              .userModuleUiUrl('/login?accessDenied=true')
              .subscribe((url) => (window.location.href = url));
            return of(null as User);
          } else {
            throw error;
          }
        })
      )
      .subscribe((user) => {
        this._currentUser.next(user);
        if (success != null) {
          success(user);
        }
      });
  }

  reloadSelectedCompany(success?: (company: Company) => void): void {
    this.companyService.current().subscribe((company) => {
      this._selectedCompany.next(company);
      success(company);
    });
  }
}

export enum UserRolesEnum {
  BLACKLIST = 'BLACKLIST',
  PEP = 'PEP',
  ADVERSE_MEDIA = 'ADVERSE_MEDIA',
  ANALYST_L2 = 'ANALYST_L2',
  SEARCH = 'SEARCH',
  API_USER_TOKEN = 'API_USER_TOKEN',
  ADMIN = 'ADMIN',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  IMPORTER = 'IMPORTER',
  INSPECTOR = 'INSPECTOR',
  RESTX_ADMIN = 'restx-admin',
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {User} from '../../domain/user/user/User';
import {UserModification} from '../../domain/user/user/UserModification';
import {UserSearchCriteria} from '../../domain/user/user/UserSearchCriteria';
import {PaginatedUsers} from '../../domain/user/user/PaginatedUsers';
import {SearchParams} from '../../../../domain/SearchParams';
import {NumberResponse} from '../../../../domain/NumberResponse';
import {BooleanResponse} from '../../../../domain/BooleanResponse';
import {UserCompanyModification} from '../../domain/user/user/UserCompanyModification';
import {Reference} from '../../../../domain/Reference';
import {Company} from '../../domain/company/Company';

@Injectable()
export class UserService {
  private readonly baseUrl = '/api/users';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public current(): Observable<User> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.transformResponseTo(User).get<User>(url + '/current')
        )
      );
  }

  public save(userModification: UserModification): Observable<User> {
    if (userModification.id !== undefined) {
      return this.update(userModification);
    } else {
      return this.create(userModification);
    }
  }

  public create(userModification: UserModification): Observable<User> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.transformResponseTo(User).post<User>(url, userModification)
        )
      );
  }

  public update(userModification: UserModification): Observable<User> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.transformResponseTo(User).put<User>(url, userModification)
        )
      );
  }

  public updateCurrentActiveBlacklistPepLevel(
    activeBlacklistPepLevel: string
  ): Observable<User> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(User)
            .put<User>(
              url + '/activeBlacklistPepLevel/' + activeBlacklistPepLevel,
              null
            )
        )
      );
  }

  public saveInCompany(
    userModification: UserCompanyModification,
    companyId: string,
    captchaToken: string
  ): Observable<User> {
    if (userModification.id !== undefined) {
      return this.updateInCompany(userModification, companyId, captchaToken);
    } else {
      return this.createInCompany(userModification, companyId, captchaToken);
    }
  }

  public createInCompany(
    userModification: UserCompanyModification,
    companyId: string,
    captchaToken: string
  ): Observable<User> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(User)
            .post<User>(url + '/company/' + companyId + '?captchaToken=' + captchaToken, userModification)
        )
      );
  }

  public updateInCompany(
    userModification: UserCompanyModification,
    companyId: string,
    captchaToken: string
  ): Observable<User> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(User)
            .put<User>(url + '/company/' + companyId + '?captchaToken=' + captchaToken, userModification)
        )
      );
  }

  public delete(id: string): Observable<User> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.transformResponseTo(User).delete<User>(url + '/' + id)
        )
      );
  }

  public getById(id: string): Observable<User> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.transformResponseTo(User).get<User>(url + '/' + id)
        )
      );
  }

  public search(params?: UserSearchCriteria): Observable<PaginatedUsers> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http.transformResponseTo(PaginatedUsers).get<PaginatedUsers>(url, {
          params: SearchParams.toHttpParams(params)
        })
      )
    );
  }

  public searchCount(params?: UserSearchCriteria): Observable<NumberResponse> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(NumberResponse)
          .get<NumberResponse>(url + '/count', {
            params: SearchParams.toHttpParams(params)
          })
      )
    );
  }

  public searchCountCompanyAdmin(
    companyRef: Reference<Company>
  ): Observable<NumberResponse> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(NumberResponse)
          .get<NumberResponse>(url + '/count/company/admin', {
            params: {company: companyRef}
          })
      )
    );
  }

  public isExistingEmail(email: string): Observable<BooleanResponse> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(BooleanResponse)
            .get<BooleanResponse>(url + '/email/' + email + '/exist')
        )
      );
  }

  public getColleague(role: string): Observable<User[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(User)
            .get<User[]>(`${url}/colleague?role=${role}`)
        )
      );
  }

  public getInspectors(companyId: string): Observable<User[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(User)
            .get<User[]>(`${url}/inspectors/${companyId}`)
        )
      );
  }
}

<m-env-banner [closeBtn]="false"
              *ngIf="showEnvBanner"
              [envName]="envName">
</m-env-banner>
<div class="grid-x align-stretch app-login">
  <div
    class="
      small-24
      large-14
      cell
      app-login-intro
      small-order-2
      large-order-1
      grid-y">
    <div class="cell shrink grid-y large-auto loginIntro">
      <div class="cell shrink loginCatchphrases">
        <div class="loginCatchphrases-sentence">
          <span>{{'login.text' | translate}}</span>
          <span class="_sub">{{'login.subtext' | translate}}</span>
        </div>
      </div>
<!--      Default login message -->
      <div class="cell shrink">
        <div class="grid-x grid-padding-x show-for-medium">
          <div class="cell small-24 medium-auto">
            <div class="loginConcept-title">
              {{'login.concept.1.title' | translate}}
            </div>
          </div>
          <div class="cell small-24 medium-auto">
            <div class="loginConcept-title">
              {{'login.concept.2.title' | translate}}
            </div>
          </div>
          <div class="cell small-24 medium-auto">
            <div class="loginConcept-title">
              {{'login.concept.3.title' | translate}}
            </div>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-24 medium-auto">
            <div class="loginConcept-title show-for-small-only">
              {{'login.concept.1.title' | translate}}
            </div>
            <p class="loginConcept-text">
              {{'login.concept.1.text' | translate}}
            </p>
          </div>
          <div class="cell small-24 medium-auto">
            <div class="loginConcept-title show-for-small-only">
              {{'login.concept.2.title' | translate}}
            </div>
            <p class="loginConcept-text">
              {{'login.concept.2.text' | translate}}
            </p>
          </div>
          <div class="cell small-24 medium-auto">
            <div class="loginConcept-title show-for-small-only">
              {{'login.concept.3.title' | translate}}
            </div>
            <p class="loginConcept-text">
              {{'login.concept.3.text' | translate}}
            </p>
          </div>
        </div>
<!--        Maintenance message -->
<!--       <div class="loginIntro-new">
         <spacer [size]="24"></spacer>
         <div class="grid-x grid-padding-x">
           <div class="cell shrink">
            <img src="assets/images/png/logo-beclm-color.png" width="64px" style="margin-right: 16px"/>
           </div>
           <div class="cell auto">
             <h4 class="loginIntro-new-title" style="color: white; font-size: 22px;">
               L'application BeCLM sera inaccessible de 19h00 à 21h00, jeudi 14 mars
             </h4>
           </div>
         </div>

         <div class="grid-x loginIntro-new-content">
           <div class="cell small-24 grid-x grid-padding-x">
             <div class="cell shrink">
               <img src="assets/images/svg/arrow-right.svg" style="width: 54px; margin-right: 24px;"/>
             </div>
             <span class="cell auto loginIntro-new-mention" style="font-weight: bold; font-style: normal; font-size: 20px;">
               Dès 21h00, l'intégralité de nos services sera à nouveau disponible.
             </span>
           </div>
         </div>
         <spacer [size]="24"></spacer>
       </div>-->
      </div>
      <div class="cell shrink loginCatchphrases">
        <div class="loginCatchphrases-footer">
          {{'login.footer.text1' | translate}} <span class="_sub">{{'login.footer.text2' | translate}}</span>
        </div>
      </div>
    </div>
  </div>

  <div
    class="
      grid-x
      small-24
      large-10
      cell
      align-middle align-center
      app-login-form
      small-order-1
      large-order-2
    "
  >
    <a href="https://www.beclm.com">
      <span class="entity-logo"></span>
    </a>
    <div class="small-20 medium-12 large-16 cell block-container">
      <!-- Tabs Login -->
      <div class="grid-x bloc-login-head">
        {{'login.sign-in' | translate}}
      </div>

      <!-- Connexion Tabs -->
      <div class="block-content" [hidden]="selectedTab != signInTab">
        <!-- Connexion Form -->
        <form
          class="form-connect"
          [class.forgot-password-open]="forgotPasswordMode || renewPasswordMode"
        >
          <div class="grid-container">
            <div class="grid-x">
              <div class="small-24 cell input-has-icon-left with-label-top">
                <a-input
                  [(ngModel)]="login"
                  name="login"
                  required
                  [label]="'login.sign-in.id' | translate"
                  [placeholder]="'login.sign-in.id' | translate"
                  [faIcon]="'fa fa-user'"
                >
                </a-input>
              </div>
              <div class="small-24 cell input-has-icon-left with-label-top pos-relative">
                <spacer [size]="16"></spacer>
                <a-input
                  [(ngModel)]="password"
                  [displayLink]="true"
                  name="password"
                  [inputType]="show ? 'text' : 'password' "
                  required
                  [label]="'login.sign-in.password' | translate"
                  [placeholder]="'login.sign-in.password' | translate"
                  [faIcon]="'fa fa-lock'">
                </a-input>

                <a (click)="changePasswordVisibility()"
                        class="passwordVisibility"
                        [title]="show ? 'Hide password' : 'Show password'">
                  <i class="fa fa-eye-slash" [ngClass]="{'fa-eye': show, 'fa-eye-slash': !show}"></i>
                </a>
                <spacer [size]="16"></spacer>
              </div>
              <div class="small-24 cell text-right">
                <a-link-default size="LARGE" (click)="forgotPasswordMode = !forgotPasswordMode" link>
                  {{'login.sign-in.password.forget' | translate}}
                </a-link-default>
              </div>
              <spacer [size]="16"></spacer>
            </div>

            <div class="grid-x">
              <div
                class="callout alert small has-icon-alert full-width"
                [hidden]="!badLogin"
              >
                {{'login.error.identificationFail' | translate}}
                <br/>{{'login.issue.password' | translate}}
              </div>
              <div
                class="callout alert small has-icon-alert full-width"
                [hidden]="!lockedUser"
              >
                {{'login.error.lockedUser' | translate}}
              </div>
              <div
                class="callout alert small has-icon-alert full-width"
                [hidden]="!passwordExpired"
              >
                {{'login.error.passwordExpired' | translate}}
              </div>
              <div
                class="callout alert small has-icon-alert full-width"
                [hidden]="!accessDenied"
              >
                {{'login.error.accessDenied' | translate}}
              </div>
            </div>

            <div class="small-24 cell">
              <a-button-submit
                id="signIn"
                type="submit"
                [disabled]="login == null || password == null || login === '' || password === ''"
                (btnClick)="logoutAndSignIn()"
                [expanded]="true"
                [faIcon]="'icon-simple-right'"
                [labelKey]="'login.sign-in.action' | translate"
              >
              </a-button-submit>
              <spacer [size]="16"></spacer>
              <a-button-default
                [labelKey]="'login.register.action' | translate"
                [expanded]="true"
                [hollow]="true"
                *ngIf="registrationEnabled"
                [btnRouterLink]="['/register']"
              >
              </a-button-default>
            </div>
            <spacer [size]="34"></spacer>

            <hr>

            <div
              class="
                grid-y grid-margin-y

              "
            >
              <a
                class="cell small-24 loginModule serviceDeskModule"
                target="_blank"
                [href]="serviceDeskUrl"
              >
                <div class="loginModule-logo">
                  <img
                    src="common/assets/images/svg/modules-icons/beclm_service_desk_logo_white.svg"
                    alt=""
                  />
                </div>
                <div class="loginModule-name">
                  {{'common.service-desk' | translate}}
                </div>
                <div class="loginModule-link">
                  <i class="icon-external-link"></i>
                </div>
              </a>
              <a
                class="cell small-24 loginModule astreeModule"
                target="_blank"
                [href]="astreeUrl"
              >
                <div class="loginModule-logo">
                  <img src="assets/images/svg/astree_beclmLogin.svg" alt=""/>
                </div>
                <div class="loginModule-name">
                  {{'common.astreeConformity' | translate}}
                </div>
                <div class="loginModule-link">
                  <i class="icon-external-link"></i>
                </div>
              </a>
            </div>
          </div>
        </form>

        <form
          class="form-forgot-password"
          #f="ngForm"
          password-confirmation
          [class.forgot-password-close]="!renewPasswordMode"
        >
          <div class="grid-container">
            <spacer [size]="16"></spacer>
            <div class="grid-x">
              <div class="callout primary small has-icon-info full-width">
                {{'login.definePassword.choosePassword' | translate}}
              </div>
            </div>

            <div class="small-24 cell input-has-icon-left with-label-top">
              <a-input
                [(ngModel)]="password"
                name="password"
                required
                [inputType]="'password'"
                [label]="'login.sign-up.password' | translate"
                [placeholder]="'login.sign-up.password.placeholder' | translate"
                [faIcon]="'fa fa-lock'"
              >
              </a-input>
            </div>

            <div class="small-24 cell input-has-icon-left with-label-top">
              <a-input
                [(ngModel)]="passwordConfirmation"
                name="password-confirmation"
                required
                [inputType]="'password'"
                [label]="'login.sign-up.password.confirm' | translate"
                [placeholder]="'login.sign-up.password.confirmation.placeholder' | translate"
                [faIcon]="'fa fa-lock'"
              >
              </a-input>
            </div>

            <div
              class="callout alert small alert"
              *ngIf="this.securityPolicy"
              [hidden]="this.password === undefined || this.password.match(this.securityPolicy.pwdPattern)"
            >
              <ul>
                <li>{{securityPolicy.pwdPatternDescription}}</li>
              </ul>
            </div>
            <div
              class="callout alert small alert"
              [hidden]="!this.samePreviousPassword"
            >
              <ul>
                <li>
                  {{'login.error.samePreviousPasswordNotAllowed' | translate}}
                </li>
              </ul>
            </div>
            <div
              class="callout alert small alert"
              [hidden]="!f.form.hasError('password-confirmation')"
            >
              <ul>
                <li>{{'login.error.password-confirmation' | translate}}</li>
              </ul>
            </div>

            <div class="grid-x space-top-25">
              <div class="small-12 cell">
                <button
                  type="button"
                  class="button medium grey has-icon-left hollow"
                  (click)="renewPasswordMode = !renewPasswordMode"
                >
                  <i class="icon-simple-left"></i>{{'common.cancel' |
                  translate}}
                </button>
              </div>

              <div class="small-12 cell text-right">
                <button
                  type="submit"
                  [disabled]="!passwordMatchWithSecurityPolicy() || f.form.invalid"
                  class="button medium success has-icon-left ripple"
                  (click)="renewPassword()"
                >
                  <i class="icon-check"></i>{{'common.comfirm' | translate}}
                </button>
              </div>
            </div>
          </div>
        </form>

        <form
          class="form-forgot-password"
          [class.forgot-password-close]="!forgotPasswordMode"
        >
          <div class="grid-container">
            <div class="grid-x">
              <div class="callout primary small has-icon-info full-width">
                {{'login.forgotPassword.explain' | translate}}
              </div>
            </div>

            <div class="small-24 cell input-has-icon-left with-label-top">
              <a-input
                [(ngModel)]="login"
                name="login"
                required
                [label]="'login.forgotPassword.email' | translate"
                [placeholder]="'login.forgotPassword.enterEmail' | translate"
                [faIcon]="'fa fa-envelope'"
              >
              </a-input>
              <spacer [size]="16"></spacer>
            </div>

            <div class="grid-x space-top-25">
              <div class="small-12 cell">
                <a-button-cancel
                  (btnClick)="forgotPasswordMode = !forgotPasswordMode"
                  [labelKey]="'common.cancel' | translate"
                >
                </a-button-cancel>
              </div>

              <div class="small-12 cell text-right">
                <a-button-submit
                  [validate]="true"
                  [labelKey]="'common.comfirm' | translate"
                  [disabled]="login == null || login === ''"
                  (btnClick)="forgotPassword()"
                >
                </a-button-submit>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="small-20 medium-12 large-16 cell blockInfo">
      <div class="cell shrink grid-x grid-padding-y align-middle">
        <div class="cell small-24">
          <i class="blockInfo-icon icon-info-circle"></i>
          <strong>{{'login.issue' | translate}}</strong>
          <ul class="blockInfo-list">
            <li>
              {{'login.issue.cache1' | translate}}
              <span class="shortcut">{{'login.issue.shortcut1' | translate}}</span>
              {{'login.issue.cache2' | translate}}
              <span class="shortcut">{{'login.issue.shortcut2' | translate}}</span>
              {{'login.issue.cache3' | translate}}
            </li>
            <li>{{'login.issue.password' | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
